// src/Form.js
import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from './firebase';
import betalogo from '../src/assets/BETA-Logo1.png';
import androidlogo from './assets/AndroidiOS.png';
import weblogo from './assets/WebApp.jpg'; 
import "./App.css";


function MainPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await addDoc(collection(db, "inquiry"), {
        name: name,
        email: email,
        message: message,
        timestamp: new Date()
      });
      setSuccess(true);
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="container">     

      {/* Header Section */}
      <header className="header">
        <h1>WE ARE B.E.T.A</h1>
        <strong><p>Where Software Meets Endless Possibilities</p></strong>
      </header>

      {/* Welcome Section */}
      <section className="section welcome">
        <div className="content">
          <h2>WELCOME TO B.E.T.A</h2>
          <p>
            Breakthrough Engineering and Technology Applications. <br />
            One Stop Solution for Software Development & More.
          </p>
          <p>
            We have delivered more than 15+ end-to-end products in the past 02
            years ranging from ERP, Accounting, Ecommerce, RPA and Custom made
            solutions.
          </p>
        </div>
        <img
          className="section-image"
          src={betalogo}
          alt="BETA Edge Logo"
        />
      </section>

      {/* What We Do Section */}
      <section className="section what-we-do">
        <img
          className="section-image"
          src={betalogo}
          alt="BETA Edge Logo"
        />
        <div className="content">
          <h2>WHAT WE DO</h2>
          <p>
            We specialize in high-performance web and mobile applications,
            focusing on cross-platform and hybrid solutions. Our comprehensive
            services extend from digital marketing strategies to full-scale
            startup launches. We have got you covered.
          </p>
        </div>
      </section>

      {/* Web Applications Section */}
      <section className="section web-applications">
        <div className="content">
          <h2>WEB APPLICATIONS</h2>
          <p>
            High-performance web applications for all-round needs, no matter the
            platform. These applications are designed to deliver seamless, fast,
            and reliable performance across all devices and operating systems.
          </p>
        </div>
        <img
          className="section-image-android-web"
          src={weblogo}
          alt="BETA Edge Logo"
        />
      </section>

      {/* Mobile Applications Section */}
      <section className="section mobile-applications">
        <img
          className="section-image-android-web"
          src={androidlogo}
          alt="BETA Edge Logo"
        />
        <div className="content">
          <h2>MOBILE APPLICATIONS</h2>
          <p>
            Cross Platform Mobile Applications with native-level performance for
            Android and iOS. Unlock the potential of your mobile apps with
            unparalleled speed and reliability.
          </p>
        </div>
      </section>



  <div className="contact-description">
    <p>
      We're here to help you bring your ideas to life. Whether you have a question,
      need support, or are ready to start your next big project, we are eager to
      assist. Reach out now and experience our commitment to excellence and innovation.
      Don't hesitate, let's create something amazing together!
    </p>
  </div>

      {/* Contact Section */}
      <section className="section contact">
        <h2>GET IN TOUCH</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input type="text" id="name" placeholder="Name" 
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            />
          </div>
          <div className="form-group">
            <input type="email" id="email" placeholder="Email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <textarea id="message" placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit Query'}
          </button>
        </form>
        
        {/* Modal */}
        {success && <p>Message sent successfully!</p>}

        <p className="contact-info">
          <strong>B.E.T.A Edge Inc.</strong> <br/>
          <br/>
          <strong>Global & Innovation Center:</strong><br/>
         
          Kolkata, India. <br />
          <br/>
          <strong>Our Nexus Points:</strong>
          <br/>

          Buona Vista, Singapore. <br/>
          New Taipei City, Taiwan.<br/>
          Kowloon Tong, Hong Kong. <br/>
          
          <strong>Dial Us:</strong> (+65) 9167 3307.
        </p>
      </section>

	  <footer className="footer">
	  <p>&copy; 2024 B.E.T.A Edge Inc. All rights reserved.</p>
	  </footer>

    </div>
  );
}

export default MainPage;

//Integrate the Modal from Chat GPT