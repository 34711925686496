// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCzTaKmHvsOqU2dOC4-615XW5tg6EScCUU",
    authDomain: "onpressed-backend.firebaseapp.com",
    databaseURL: "https://onpressed-backend.firebaseio.com",
    projectId: "onpressed-backend",
    storageBucket: "onpressed-backend.appspot.com",
    messagingSenderId: "871055154325",
    appId: "1:871055154325:web:b9ecc002d85efe00c5cf9c",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
